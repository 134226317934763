.hamburger {
	padding: 15px 15px;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;
}
.hamburger:hover {
	opacity: 0.7;
}
.hamburger.is-active:hover {
	opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
	background-color: #000;
}
.hamburger-box {
	width: 40px;
	height: 24px;
	display: inline-block;
	position: relative;
}
.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	width: 40px;
	height: 4px;
	background-color: #000;
	border-radius: 4px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
	content: '';
	display: block;
}
.hamburger-inner::before {
	top: -10px;
}
.hamburger-inner::after {
	bottom: -10px;
}

.hamburger--collapse .hamburger-inner {
	top: auto;
	bottom: 0;
	transition-duration: 0.13s;
	transition-delay: 0.13s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
	top: -20px;
	transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
	transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
		transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
	transform: translate3d(0, -10px, 0) rotate(-45deg);
	transition-delay: 0.22s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
	top: 0;
	opacity: 0;
	transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
	top: 0;
	transform: rotate(-90deg);
	transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
		transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger-inner-white,
.hamburger-inner-white::before,
.hamburger-inner-white::after {
	background-color: white;
}
.banner nav.is-active {
	display: flex;
	justify-content: flex-start;
}

.hamburger {
	display: none;
}

nav.is-active {
	@media (max-width: 768px) {
		flex-direction: column;
		align-items: start;
		justify-content: start;
	}
}

.banner .mobile-logo {
	display: none;
}

@media only screen and (max-width: 768px) {
	.hamburger {
		outline: none;
		padding: 0.5em 0 !important;
		right: 0.5em;
		top: 0;
		display: block;
	}

	.hamburger .hamburger-box {
		width: 25px;
	}

	.hamburger .hamburger-inner,
	.hamburger .hamburger-inner:after,
	.hamburger .hamburger-inner:before {
		width: 25px;
	}

	.hamburger.is-active {
		z-index: 2;
		position: absolute;
		padding: 1.5em 1em !important;
		opacity: 0.7;
	}

	.hamburger.is-active .hamburger-inner,
	.hamburger.is-active .hamburger-inner:after,
	.hamburger.is-active .hamburger-inner:before {
		background-color: #fff !important;
	}

	.banner nav {
		transform: translateX(100%);
		transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
		display: block;
	}

	.banner nav.is-active {
		transform: translate3d(0, 0, 0);
	}

	.banner nav {
		position: fixed;
		top: 0;
		bottom: 0;
		width: 100%;
		background-color: #000;
		right: 0;
		text-align: start;
	}
	.banner nav a {
		display: block;
	}
	.banner .mobile-logo {
		padding: 4em 2em 1.5em 2em;
	}
	.banner .mobile-logo img {
		max-width: 17em;
	}
	.banner nav a,
	.banner nav a.login,
	.banner nav a.getapp {
		color: #fff;
		background: none;
		border: none;
		display: block;
		padding: 0.5em 1.5em;
		font-size: 1.7em;
	}
	.banner nav a:hover {
		background: none !important;
		text-decoration: underline !important;
		color: #fff !important;
	}
	.banner nav .sep {
		display: none;
	}
	.banner .mobile-logo {
		display: block;
	}
}

.banner nav .browse-content {
	padding: 2.5em 1.7em 0.5em 1.7em;
	font-size: 1.5em;
	text-transform: uppercase;
	font-weight: 600;
	color: #5d6064;
}

@media (min-width: 769px) {
	.banner nav a.login {
		border-radius: 30px;
		color: #6f42c1;
		border: 2px solid #6f42c1;
		padding: 8px 25px;
		margin: auto 5px;
		cursor: pointer;
	}

	.banner nav a.login:hover {
		background-color: #6f42c1;
		color: #fff;
		text-decoration: none;
	}
}

.plus-badge {
	background-image: url('https://cdnweb.anghami.com/web/assets/img/logos/PLUS_BADGE.png');
}
.gold-badge {
	background-image: url('https://cdnweb.anghami.com/web/assets/img/gold_badge.png');
}

.plan-badge {
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	position: relative;
	width: 80%;
	height: 2.3em;
	color: #fff;
	text-align: center;
	border-radius: 3em;
	text-transform: uppercase;
	font-size: 0.5em;
	left: 0;
	right: 0;
	bottom: 0.7em;
	line-height: 1.4em;
	margin-top: -0.8em;
	@media (max-width: 768px) {
		display: none;
	}
}

.user-image {
	border-radius: 50%;
	max-width: 3rem;
	cursor: pointer;
	@media (max-width: 768px) {
		display: none;
	}
}

.sep {
	margin: auto 10px;
}

.banner a {
	text-decoration: none;
	font-weight: bold;
	color: black;
	padding: 0 1em;
	@media (max-width: 768px) {
		color: white;
	}
}

.spacing {
	margin: 0 1em;
}
