.header {
	position: fixed;
	left: 0;
	width: 100%;
	top: 0;
	background: #fff;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
	z-index: 50;
	padding: 1.2em 1.5em;
	height: 6.2em;
	direction: ltr;
}

.header .logo {
	width: 150px;
}

.header-logo-link {
	@media (max-width: 768px) {
		margin: 0.5rem 0;
	}
}
